import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { LoaderOverlayProvider } from './components/Loader';
import ApiProvider from './api/ApiProvider';
import ScrollToTopOnRouteChange from './utils/ScrollToTopOnRouteChange';
import IsLoggedInSubscriber from './api/IsLoggedInSubscriber';
import Routes from './routing/Routes';
import Header from './layout/Header';
import PreFooter from './layout/PreFooter';
import Footer from './layout/Footer';
import CookieLayer from './components/CookieLayer/CookieLayer';

import './bootstrap.css';
import './sass/style.scss';
import keyboardFocus from 'keyboard-focus';

const cookieAccepted = window.localStorage.getItem('cookieAccepted');

export default () => {
  keyboardFocus(document);
  const [showCookie, setShowCookie] = useState(!cookieAccepted);

  showCookie ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto');

  return (
    <LoaderOverlayProvider>
      <ApiProvider>
        <Router>
          <ScrollToTopOnRouteChange />
          <IsLoggedInSubscriber>
            <Header />
          </IsLoggedInSubscriber>

          {showCookie && <CookieLayer setShowCookie={setShowCookie} />}

          {/* Load routes with switch */}
          <Routes />

          <PreFooter />
          <Footer />
        </Router>
      </ApiProvider>
    </LoaderOverlayProvider>
  );
};
