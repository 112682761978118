import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useGetSites } from '../api/atmPublic';

const excludeSites = ['/imprint'];

const FooterLinks = ({ links }) =>
  links.map(([link, title]) => (
    <li
      key={link}
      className="footer-menu__item"
    >
      <NavLink
        to={link}
        className="footer-menu__link"
      >
        {title}
      </NavLink>
    </li>
  ));

const Footer = () => {
  const { data } = useGetSites();

  const appFooterLinks = [
    ['/kontakt-aufnehmen', 'Kontakt aufnehmen'],
    ['/teilnahmebedingungen', 'Teilnahmebedingungen'],
  ];

  const atmFooterLinks = data
    ? data.map(({ name, title }) => ['/' + name, title]).filter(([name]) => !excludeSites.includes(name))
    : [];

  return (
    <footer className="footer clearfix">
      <Grid fluid={true}>
        <div className="footer-wrapper">
          <div className="copyright">© {new Date().getFullYear()} Siemens-Betriebskrankenkasse</div>
          <ul className="footer-menu">
            <FooterLinks links={[...appFooterLinks, ...atmFooterLinks]} />
          </ul>
        </div>
      </Grid>
    </footer>
  );
};

export default Footer;
