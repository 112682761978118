import React, { useState } from 'react';
import { Grid } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { ReactComponent as LogoSBKWithoutClaim } from '../logo/logo-sbk.svg';
import { ReactComponent as LogoSBKWithClaim } from '../logo/logo-sbk-with-claim.svg';

const commonMenuLinks = [
  ['/die-aktion', 'Die Aktion'],
  ['/richtig-aufladen', 'Richtig aufladen'],
];

const loggedOutMenuLinks = [['/login', 'Login'], ...commonMenuLinks];

const loggedInMenuLinks = [
  ...commonMenuLinks,
  ['/trinktagebuch', 'Trinktagebuch'],
  ['/team', 'Team'],
  ['/rankings', 'Rankings'],
  ['/daten-aendern', 'Daten ändern'],
  ['/logout', 'Logout'],
];

const HeaderLogo = () => (
  <div className="logo">
    <NavLink to="/">
      <LogoSBKWithClaim
          className="logo-with-claim"
          alt="Logo der SBK"
      />
      <LogoSBKWithoutClaim
          className="logo-without-claim"
          alt="Logo der SBK"
      />
    </NavLink>
  </div>
);

const HeaderMenu = ({ isLoggedIn }) => {
  const [showMenu, setShowMenu] = useState(false);
  const headerLinks = isLoggedIn ? loggedInMenuLinks : loggedOutMenuLinks;

  const openMenu = () => setShowMenu(true);
  const closeMenu = () => setShowMenu(false);

  return (
    <>
      <button
        className="menu-icon"
        onClick={openMenu}
      />
      <nav className={'menu' + (showMenu ? ' menu-panel-touch menu-panel-touch_state_open' : '')}>
        <ul className="menu__list">
          {headerLinks.map(([link, name]) => (
            <li
              key={link}
              className="menu__item"
            >
              <NavLink
                to={link}
                className="menu__link"
                activeClassName="selected"
                onClick={closeMenu}
              >
                {name}
              </NavLink>
            </li>
          ))}
        </ul>
        <span
          className="menu-panel-touch__close"
          onClick={closeMenu}
        />
      </nav>
    </>
  );
};

const Header = ({ isLoggedIn = false }) => (
  <header className="header clearfix">
    <Grid fluid={true}>
      <HeaderLogo />
      <HeaderMenu isLoggedIn={isLoggedIn} />
    </Grid>
  </header>
);

export default Header;
