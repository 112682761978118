/* Generated by restful-react */

import React from 'react';
import { Get, GetProps, useGet, UseGetProps, Mutate, MutateProps, useMutate, UseMutateProps } from 'restful-react';

import { useAtmApiBaseUrl, useAtmApiRequestOptions } from './ApiProvider';
export const SPEC_VERSION = '1.0.0-dev';
/**
 * Returned if you don't have the right authorization to access this resource.
 */
export interface UnauthorizedResponse {
  code?: number;
  message?: string;
}

export interface BadRequestResponse {
  code?: number;
  message?: string;
}

export interface BadRequestDetailResponse {
  type?: string;
  title?: string;
  detail?: string;
  /**
   * If the values you provided fail common validation, the validation errors are listed unter violations. The messages are translated so you can directly output the 'message' value to the user.
   */
  violations?: {
    /**
     * Which property is not valid
     */
    propertyPath?: string;
    /**
     * A translated errror message that can be directly showed to the user.
     */
    message?: string;
  }[];
}

/**
 * A description of the form fields. Use this to build the form or, if you already submitted data, to rebuild it.
 */
export type FormFieldsArray = {
  name?: string;
  label?: string;
  /**
   * Pass this as type to the input.
   *
   * The special type `compound` means that this property consist of multiple form fields,
   * render them all in a row with one common label. Compound fields have no value proeprty
   * (The values are on the children fields). See `children` below.
   *
   * The special type `choice` represents a select. See `choices` below.
   *
   */
  type?: string;
  /**
   * If this field is required for submitting the form.
   */
  required?: boolean;
  /**
   * If the field is of type `compund`, this will contain the children form fields.
   */
  children?: {
    /**
     * This and the main field name result in the actual name you have to pass to your input, e.g. "color[0]".
     */
    name?: string;
    type?: string;
    required?: boolean;
    choices?: {
      label?: string;
      value?: string;
    }[];
    /**
     * The previous value of this field. Only present if there was a previous value.
     */
    value?: string;
  }[];
  /**
   * If the field is of type `choice`, this will contain the possible choices.
   */
  choices?: {
    label?: string;
    value?: string;
  }[];
  /**
   * Translated description of the error. Only present if there was an error with this field.
   */
  error?: string;
  /**
   * The base message used to translate the error. Use this if you want to handle some error directly. Like suggesting the user to log in if the email is already taken when registering.
   */
  errorKey?: string;
  /**
   * The previous value of this field. Only present if there was a previous value.
   */
  value?: string;
}[];

/**
 * What data should the statistic contain?
 *
 * New statistic data always completely replaces the old one, you always have to send all statistics
 * that should be shown in the admin backend to the SBK.
 *
 * Statistics are divided into rows and columns (2 dimensional array). Every row can have two columns.
 * If one statistic should span two columns, you can set `"columns": 2,` on the statistic.
 *
 */
export type StatisticsArray = {
  /**
   * The name of the statistic. Can be anything. Use it to describe the current
   * statistic.
   *
   */
  name?: string;
  /**
   * Every row can have two columns. If one statistic should span two columns, you can
   * set this to 2.
   *
   */
  columns?: number;
  /**
   * The type of the statistic. This defines the format of the statistic data below.
   *
   */
  type?: 'survey-results' | 'table';
  /**
   * The statistic data. This is different for every statistic type. See below for
   * examples.
   *
   * ### Type "survey-results"
   * ```json
   * {
   *   "Allgemein": [
   *     {
   *       "name": "Altersverteilung",
   *       "answers": [
   *         {
   *           "name": "0-25 Jahre",
   *           "value": 9
   *         },
   *         {
   *           "name": "26-35 Jahre",
   *           "value": 13
   *         },
   *         {
   *           "name": "36-45 jahre",
   *           "value": 5
   *         },
   *         {
   *           "name": "46-55 Jahre",
   *           "value": 10
   *         },
   *         {
   *           "name": "56 Jahre und älter",
   *           "value": 6
   *         }
   *       ]
   *     }
   *   ],
   *   "Allgemeiner Gesundsheitszustand": [
   *     {
   *       "name": "Wie würden Sie Ihren Gesundheitszustand im Allgeminen beschreiben?",
   *       "answers": [
   *         {
   *           "name": "Sehr gut",
   *           "value": 5
   *         },
   *         {
   *           "name": "Gut",
   *           "value": 29
   *         },
   *         {
   *           "name": "Eher gut",
   *           "value": 5
   *         },
   *         {
   *           "name": "Eher schlecht",
   *           "value": 3
   *         },
   *         {
   *           "name": "Schlecht",
   *           "value": 1
   *         },
   *         {
   *           "name": "Sehr schlecht",
   *           "value": 0
   *         }
   *       ]
   *     }
   *   ]
   * }
   * ```
   *
   * ### Type "table"
   * "data" is two dimensional array with the table rows and columns. Every column can
   * either be a scalar value (string, int, float, etc.) or a value object with custom
   * style defintions.
   * ```json
   * [
   *   [
   *     "Value of first column in first row",
   *     "Value of second column in first row",
   *     "Value of third column in first row"
   *   ],
   *   [
   *     "Value of first column in second row",
   *     "",
   *     "<- The previous column is empty"
   *   ],
   *   [
   *     "First column in third row",
   *     {
   *       "value": "Value with custom style, you can use whatever CSS you want",
   *       "style": "font-weight: bolder;background-color: red;"
   *     },
   *     {
   *       "value": "In addition to \"style\", \"rowspan\" and \"colspan\" are also supported",
   *       "style": "font-style:italic;background-color:green;",
   *       "rowspan": 2
   *     }
   *   ],
   *   [
   *     {
   *       "value": "Colspan-Test",
   *       "style": "background-color: black;color: white;border-top:0;",
   *       "colspan": 2
   *     }
   *   ],
   *   [
   *     "All scalar values are supported:",
   *     34,
   *     45.6
   *   ],
   *   [
   *     "",
   *     "",
   *     "Line-breaks are\nsupported\ntoo"
   *   ]
   * ]
   * ```
   *
   * ### Legacy format for type "table" when you have a defined header and body
   * ```json
   * {
   *   "headers": [
   *     "Platz",
   *     "Teilnehmer",
   *     "Getrunkene Liter"
   *   ],
   *   "values": [
   *     [1, "Moritz Test", 1002],
   *     [2, "Manuela Müller", 992],
   *     [3, "Franz Peter", 903],
   *     [4, "Max Müller", 763],
   *     [5, "Claudia Hensen", 700],
   *     [6, "Max Mustermann", 588],
   *     [7, "Peter Stübe", 486],
   *     [8, "Lena Kowitz", 333],
   *     [9, "Micha Lauter", 330],
   *     [10, "Romi Leiser", 299]
   *   ]
   * }
   * ```
   *
   */
  data?: {}[];
}[][];

export interface ActivateAttendeeRequest {
  /**
   * The activation key from the mail.
   */
  key: string;
  /**
   * The new password, if required (only available if no previous password was set).
   */
  password?: string;
}

export interface ApiLog {
  id?: number;
  date?: string;
  campaignType?: CampaignType;
  /**
   * If a private api was called, this will contain the authenticated attendee.
   */
  attendee?: string;
  method?: string;
  url?: string;
  statusCode?: number;
  responseData?: string;
  requestData?: string;
}

export interface AppointmentRead {
  id?: number;
  start?: string;
  end?: string;
  location?: string;
  room?: string;
  serviceProvider?: string;
  informationString?: string;
  title?: string;
}

export interface AppointmentReservationRead {
  reservationKey?: string;
  createdAt?: string;
}

export interface AppointmentReservationWrite {
  reservationKey?: string;
  appointment: string;
}

export interface AppointmentWaitingListEntryRead {
  id?: number;
}

export interface AppointmentWaitingListEntryWrite {
  campaign?: string;
  registrationData?: string[];
}

export interface AttendeeAttendeeReadId {
  id?: number;
}

export interface AttendeeRead {
  id?: number;
  /**
   * One of 0 (Unknown), 1 (Misses), 2 (Mister).
   */
  salutation?: number;
  /**
   * One of 0 (None), 1 (Dr.), 2 (Prof.), 3 (Prof. Dr.).
   */
  title?: number;
  firstName: string;
  lastName: string;
  language?: string;
  dateOfBirth?: string;
  insurance: string;
  policyNumber?: string;
  /**
   * The telephone number of the attendee. It is split into three parts divided by a "|", e.g. "+49|89|1234567".
   */
  tel?: string;
  email: string;
}

export interface AttendeeWrite {
  /**
   * One of 0 (Unknown), 1 (Misses), 2 (Mister).
   */
  salutation?: number;
  /**
   * One of 0 (None), 1 (Dr.), 2 (Prof.), 3 (Prof. Dr.).
   */
  title?: number;
  firstName: string;
  lastName: string;
  language?: string;
  dateOfBirth?: string;
  insurance: string;
  policyNumber?: string;
  /**
   * The telephone number of the attendee. It is split into three parts divided by a "|", e.g. "+49|89|1234567".
   */
  tel?: string;
  email: string;
  /**
   * A non-persisted field that's used to create the encoded password.
   */
  plainPassword?: string;
}

export interface AttendeeLogoutRequest {
  [key: string]: any;
}

export interface BookingRead {
  id?: number;
  appointment?: AppointmentRead;
  campaignSlug?: string;
  organizerContact?: string;
}

export interface BookingWrite {
  appointment?: string;
  registrationData?: string[];
  /**
   * Virtual property for the booking process. Allows booking a reserved appointment.
   */
  reservationKey?: string;
}

export interface CampaignReadPortal {
  id?: number;
  name?: string;
  startDate?: string;
  endDate?: string;
  portalData?: { [key: string]: any };
  maxTeamMembers?: number;
  /**
   * Whether to use the new central registration for this campaign or not. When enabled the registration process is
   * not handled via the portal anymore but through a central registration frontend supplied by the ATM itself.
   */
  useCentralRegistration?: boolean;
}

export interface CampaignAppointmentsRegistrationFieldsRead {
  fields?: string[];
}

export interface CampaignAttendeeApi {
  gaveDataConsent?: boolean;
  gaveAdApproval?: boolean;
  /**
   * Whether to send non system relevant mails to the user.
   */
  sendMails?: boolean;
  subjectToLicense?: boolean;
  /**
   * The ATM id of the campaign the user is currently participating in.
   */
  campaignId?: number;
}

export interface CampaignCollectionCampaignCollectionRead {
  campaignCollectionRegisteredCampaigns?: CampaignCollectionRegisteredCampaignCampaignCollectionRead[];
  localisations?: {
    [key: string]: CampaignCollectionLocalisationCampaignCollectionRead;
  };
}

export interface CampaignCollectionLocalisationCampaignCollectionRead {
  title?: string;
  openingText?: string;
  closingText?: string;
}

export interface CampaignCollectionRegisteredCampaignCampaignCollectionRead {
  id?: number;
  linkUrl?: string;
  localisations?: {
    [key: string]: CampaignCollectionRegisteredCampaignLocalisationCampaignCollectionRead;
  };
}

export interface CampaignCollectionRegisteredCampaignLocalisationCampaignCollectionRead {
  campaignTitle?: string;
  teaserDescription?: string;
  importantInformation?: string;
}

export interface CampaignMail {
  id?: number;
  campaign?: string;
  type?: string;
  mailTemplate: MailTemplate;
  onlySbkInsured?: boolean;
  onlyNotActivated?: boolean;
  localisations?: CampaignMailLocalisation[];
  createdAt?: string;
  createdBy?: string;
  mails?: string[];
}

export interface CampaignMailLocalisation {
  id?: number;
  campaignMail?: CampaignMail;
  language?: string;
  subject: string;
  content: string;
}

export interface CampaignStatistic {
  id?: number;
  campaign?: string;
  data?: string[];
}

export interface CampaignStatisticUpdateCampaignStatisticRequest {
  campaignId: number;
  data?: StatisticsArray;
}

export interface CampaignType {
  username?: string;
  id?: number;
  name?: string;
  enableVTExport?: boolean;
  isOffline?: boolean;
  enableForNewCampaigns?: boolean;
  keycloakClientId?: string;
  registrationLinkTemplate?: string;
  activationLinkTemplate?: string;
  passwordResetLinkTemplate?: string;
  teamInvitationLinkTemplate?: string;
  contactReceivers?: string[];
  keyVisual4x1?: string;
  keyVisual16x9?: string;
  activateAtmRegistrationPage?: boolean;
  showLandingPageBeforeRegistrationForm?: boolean;
  allowMultipleBookingsPerMail?: boolean;
  registrationFields?: string[];
  appointmentsBookingFields?: string[];
  useLaxGreeting?: boolean;
  useCentralRegistration?: boolean;
  /**
   * If false, access to the /api/campaigns/statistics and /api/statistics APIs is disabled. This is used in case a
   * portal becomes so outdated, that we don't fully trust its data anymore. This way we can disable the statistics
   * on ATM site without the SBK having to pay immense amounts of money to the portal developers for such a simple
   * change.
   */
  enableStatisticsApis?: boolean;
  localisations?: {
    [key: string]: CampaignTypeLocalisation;
  };
  campaignFields?: PortalCampaignField[];
  campaigns?: string[];
  mailTemplates?: MailTemplate[];
  /**
   * Virtual property not stored in the db. Determines the role of this campaign type when used as the current user
   * for api calls. Gets set by the {@see PortalApiIsSecureSubscriber}.
   */
  apiIsSecure?: boolean;
  /**
   * If authenticated as portal (Keycloak JWT), the "user" always has the public api role.
   */
  roles?: string[];
  password?: string;
  salt?: string;
  statistic?: string;
  sites?: string[];
  apiLogs?: ApiLog[];
  copyMailTemplatesFrom?: CampaignType;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
}

export interface CampaignTypeLocalisation {
  id?: number;
  campaignType?: CampaignType;
  language?: string;
  defaultLandingPageContent?: string;
  appointmentsDefaultLandingPageContent?: string;
}

export interface CampaignTypeStatistic {
  id?: number;
  campaignType?: CampaignType;
  data?: StatisticsArray;
}

export interface CampaignTypeStatisticWrite {
  data?: StatisticsArray;
}

export interface ContactRequest {
  /**
   * The receiver of the contact request (e.g. "admin", "hr", etc.). If not set or an unknown receiver gets
   * specified, the "default" one gets used.
   */
  receiver?: string;
  /**
   * The first name of the person requesting the contact.
   */
  firstName: string;
  /**
   * The last name of the person requesting the contact.
   */
  lastName: string;
  /**
   * The email of the person requesting the contact. This gets set as reply-to.
   */
  email: string;
  /**
   * The message of the contact request.
   */
  message: string;
}

export interface InsuranceReadInsurance {
  id?: number;
  name?: string;
  policyNumberRequired?: boolean;
  inShortList?: boolean;
}

export interface JoinTeamRequestCreate {
  joinKey: string;
}

export interface JoinTeamRequestRead {
  campaignId?: number;
  invitedEmail?: string;
  isRegistered?: boolean;
}

export interface Mail {
  id?: number;
  campaignAttendee?: string;
  /**
   * If we send team invitation mails we only have the campaign and not the campaign attendee which is why we have
   * the campaign as extra property.
   */
  campaign?: string;
  mailTemplate?: MailTemplate;
  campaignMail?: CampaignMail;
  booking?: string;
  /**
   * Even though we have the campaign attendee relation, we still need to save the attendee id directly so the
   * embedded list view extension can show the mails for a specific attendee.
   */
  attendee?: string;
  /**
   * Save recipient in case the attendee gets deleted.
   */
  recipient?: string;
  subject?: string;
  /**
   * The greeting text (e.g. "Hello Mr. John Smith,") of the mail that gets send to EPISERVER as VARIABLE_1.
   */
  greetingText?: string;
  /**
   * The rendered content of the mail that gets send to EPISERVER as VARIABLE_2.
   */
  mailContent?: string;
  sendAt?: string;
  createdAt?: string;
  status?: string;
  /**
   * The unique mail id set by EPISERVER. Required to check the status of the mail.
   */
  episerverMailingToUserId?: string;
  sendLog?: string[];
  errorMessage?: string;
}

export interface MailCreateMailRequest {
  /**
   * The ids of the attendees that should receive the mail.
   */
  attendeeIds: number[];
  /**
   * Choose the portal mail template to use. If not set, empty, NULL or "default", the default portal mail template
   * will be used. The ID is set by the SBK. The ID does not get slugified, so if the SBK sets
   * "Reminder-Mail für 2022" in the admin interface, you have to provide this exact same string
   * "Reminder-Mail für 2022" to the API.
   */
  portalTemplateId?: string;
  /**
   * Subject of the mail. If not set, empty or NULL, the original subject of the mail template will be used. Useful
   * if using multiple portal mail templates via `portalMailTemplateId`.
   */
  subject?: string;
  var1?: string;
  var2?: string;
  var3?: string;
  var4?: string;
  var5?: string;
  /**
   * The send delay in seconds. Defaults to 0.
   */
  delay?: number;
}

export interface MailTemplate {
  /**
   * Returns the subject of the first localisation. Having at least one localisation is required so we do not need to
   * check if it exists.
   */
  identifierSubject?: string;
  id?: number;
  campaignType?: CampaignType;
  episerverTemplateId?: string;
  episerverRecipientListId?: string;
  /**
   * Prefix to add to the email sender mail address.
   */
  episerverSenderEmailPrefix?: string;
  /**
   * The name that should be display as email sender.
   */
  episerverSenderName?: string;
  episerverTemplateParameters?: string[];
  triggerType?: string;
  triggerSettings?: string[];
  sendDelay?: number;
  localisations?: MailTemplateLocalisation[];
  mails?: string[];
  campaignMails?: CampaignMail[];
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
}

export interface MailTemplateLocalisation {
  id?: number;
  mailTemplate?: MailTemplate;
  language?: string;
  subject: string;
  content: string;
}

export interface PortalCampaignField {
  id?: number;
  campaignType?: CampaignType;
  label?: string;
  key?: string;
  type?: string;
  defaultValue?: string;
  position?: number;
}

export interface ResendActivationMailRequest {
  email: string;
}

export interface ResetPasswordRequest {
  campaignType?: CampaignType;
  email?: string;
  /**
   * The key from the mail.
   */
  key?: string;
  /**
   * The new password.
   */
  password?: string;
  /**
   * Returns the purpose of this ResetPasswordRequest. Purpose will be one of the purpose constants of this class.
   */
  purpose?: string;
}

export interface ResetPasswordRequestCheckPasswordResetKey {
  /**
   * The key from the mail.
   */
  key?: string;
}

export interface ResetPasswordRequestDoPasswordReset {
  /**
   * The key from the mail.
   */
  key?: string;
  /**
   * The new password.
   */
  password?: string;
}

export interface ResetPasswordRequestRequestPasswordReset {
  email?: string;
}

export interface SiteReadCollection {
  name?: string;
  localisations?: {
    [key: string]: SiteLocalisationReadCollection;
  };
  /**
   * *DEPRECATED:* Use the localisations object instead.
   */
  title?: string;
}

export interface SiteReadItem {
  name?: string;
  localisations?: {
    [key: string]: SiteLocalisationReadItem;
  };
  /**
   * *DEPRECATED:* Use the localisations object instead.
   */
  title?: string;
  /**
   * *DEPRECATED:* Use the localisations object instead.
   */
  content?: string;
}

export interface SiteLocalisationReadCollection {
  title?: string;
}

export interface SiteLocalisationReadItem {
  title?: string;
  content?: string;
}

export interface TeamTeamCreate {
  name: string;
}

export interface TeamTeamName {
  name: string;
}

export interface TeamTeamRead {
  id?: number;
  name: string;
  /**
   * If true, the current user is captain of this team and can manage it.
   */
  userIsCaptain?: boolean;
  /**
   * Members of this team, without the captain.
   */
  members?: TeamMemberTeamRead[];
  invitations?: TeamInvitationTeamRead[];
  /**
   * Attendee id of the captain.
   */
  captainAttendeeId?: string;
  /**
   * Name of the captain.
   */
  captainName?: string;
}

export interface TeamInvitationTeamRead {
  id?: number;
  anonymizedEmail?: string;
}

export interface TeamInvitationInviteToTeamRequest {
  /**
   * Array of emails to send an invitation to.
   */
  emails?: string[];
}

export interface TeamMemberTeamRead {
  id?: number;
  /**
   * If true, the team member is the current user can be managed by it.
   */
  isUser?: boolean;
  attendeeId?: number;
  fullName?: string;
}

export interface PostLoginResponse {
  token?: string;
  /**
   * After how many seconds of inactivity the token expires. Calling a private api with the token
   * resets this timer.
   *
   */
  expires_after?: number;
}

export interface PostLoginRequestBody {
  /**
   * The email address of the attendee you want to login.
   */
  email: string;
  /**
   * The password of the attendee you want to login.
   */
  password: string;
  campaignId?: number;
  /**
   * If set, required to be true
   */
  dataConsent?: boolean;
  adApproval?: boolean;
}

export type PostLoginProps = Omit<
  MutateProps<
    PostLoginResponse,
    | BadRequestDetailResponse
    | {
        code?: number;
        message?: string;
      },
    void,
    PostLoginRequestBody,
    void
  >,
  'path' | 'verb'
>;

/**
 * Generates a token to access the private api.
 *
 * Returns a token that can be used to access the private api. For example to retrieve and update the saved data of an attendee for your portal.
 *
 * The token expires one hour after its last usage so the user gets automatically logged out.
 * If the user logs out through another portal, all login tokens are invalidated.
 *
 * ------------
 *
 * This method also gets used to register already existing attendees to new campaigns.
 * If the registration form says that the provided email already exists, the user can use the login form to register on the campaign with their existing user credentials.
 * Then, instead of returning an error that the attendee is not registered for any campaign of your portal, the login will automatically register the attendee on the provided campaign and then proceed with the normal login.
 *
 * It is possible for the SBK to add dynamic registration fields to the login form, so you first need to retrieve the form fields required for the login from the `/api/campaigns/{id}/register?forLogin=true` API.
 * The `forLogin=true` will result in the API only returning the fields required for the login.
 *
 * In addition to the fields returned by the api, **you also have to send the campaign id** to the login api** (see request format), otherwise the login api will treat the request as a normal login request.
 *
 * **Note:** On success, the login API will return an error that the user is not activated yet. **This means the registration was successfull!**
 * This is because after the registration process, the login API procceeds with the normal login checks, which then triggers the "Not activated yet" error.
 *
 */
export const PostLogin = (props: PostLoginProps) => (
  <Mutate<
    PostLoginResponse,
    | BadRequestDetailResponse
    | {
        code?: number;
        message?: string;
      },
    void,
    PostLoginRequestBody,
    void
  >
    verb="POST"
    path={`/api/login`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UsePostLoginProps = Omit<
  UseMutateProps<
    PostLoginResponse,
    | BadRequestDetailResponse
    | {
        code?: number;
        message?: string;
      },
    void,
    PostLoginRequestBody,
    void
  >,
  'path' | 'verb'
>;

/**
 * Generates a token to access the private api.
 *
 * Returns a token that can be used to access the private api. For example to retrieve and update the saved data of an attendee for your portal.
 *
 * The token expires one hour after its last usage so the user gets automatically logged out.
 * If the user logs out through another portal, all login tokens are invalidated.
 *
 * ------------
 *
 * This method also gets used to register already existing attendees to new campaigns.
 * If the registration form says that the provided email already exists, the user can use the login form to register on the campaign with their existing user credentials.
 * Then, instead of returning an error that the attendee is not registered for any campaign of your portal, the login will automatically register the attendee on the provided campaign and then proceed with the normal login.
 *
 * It is possible for the SBK to add dynamic registration fields to the login form, so you first need to retrieve the form fields required for the login from the `/api/campaigns/{id}/register?forLogin=true` API.
 * The `forLogin=true` will result in the API only returning the fields required for the login.
 *
 * In addition to the fields returned by the api, **you also have to send the campaign id** to the login api** (see request format), otherwise the login api will treat the request as a normal login request.
 *
 * **Note:** On success, the login API will return an error that the user is not activated yet. **This means the registration was successfull!**
 * This is because after the registration process, the login API procceeds with the normal login checks, which then triggers the "Not activated yet" error.
 *
 */
export const usePostLogin = (props: UsePostLoginProps) =>
  useMutate<
    PostLoginResponse,
    | BadRequestDetailResponse
    | {
        code?: number;
        message?: string;
      },
    void,
    PostLoginRequestBody,
    void
  >('POST', `/api/login`, {
    base: useAtmApiBaseUrl(),
    requestOptions: useAtmApiRequestOptions(false),
    ...props,
  });

export interface PostActivateAttendeeResponse {
  success?: boolean;
}

export type PostActivateAttendeeProps = Omit<
  MutateProps<PostActivateAttendeeResponse, BadRequestDetailResponse, void, ActivateAttendeeRequest, void>,
  'path' | 'verb'
>;

/**
 * Activates an attendee for a campaign.
 *
 * Requires the activation key send with the mail after a successfull registration.
 *
 * You should first only send the activation key. If this returns 200 the attendee is activated and you can redirect them to the login.
 * If a 400 Bad Request is returned, together with the "Please provide a new password." error, you should prompt the user for a new password (and to repeat this password) and then re-send the activation request with the password choosen by the user.
 *
 */
export const PostActivateAttendee = (props: PostActivateAttendeeProps) => (
  <Mutate<PostActivateAttendeeResponse, BadRequestDetailResponse, void, ActivateAttendeeRequest, void>
    verb="POST"
    path={`/api/activate-attendee`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UsePostActivateAttendeeProps = Omit<
  UseMutateProps<PostActivateAttendeeResponse, BadRequestDetailResponse, void, ActivateAttendeeRequest, void>,
  'path' | 'verb'
>;

/**
 * Activates an attendee for a campaign.
 *
 * Requires the activation key send with the mail after a successfull registration.
 *
 * You should first only send the activation key. If this returns 200 the attendee is activated and you can redirect them to the login.
 * If a 400 Bad Request is returned, together with the "Please provide a new password." error, you should prompt the user for a new password (and to repeat this password) and then re-send the activation request with the password choosen by the user.
 *
 */
export const usePostActivateAttendee = (props: UsePostActivateAttendeeProps) =>
  useMutate<PostActivateAttendeeResponse, BadRequestDetailResponse, void, ActivateAttendeeRequest, void>(
    'POST',
    `/api/activate-attendee`,
    {
      base: useAtmApiBaseUrl(),
      requestOptions: useAtmApiRequestOptions(false),
      ...props,
    },
  );

export interface PostAppointmentsBookingsResponse {
  success?: boolean;
  /**
   * The amount of errors. Check the error property of the fields if this is bigger than 0.
   */
  errorCount?: number;
  fields?: FormFieldsArray;
}

export type PostAppointmentsBookingsProps = Omit<
  MutateProps<PostAppointmentsBookingsResponse, BadRequestDetailResponse | void, void, BookingWrite, void>,
  'path' | 'verb'
>;

/**
 * Creates a new appointments booking.
 *
 * The required fields for the registrationData can be retrieved from `/api/campaigns/{id}/appointments-registration-fields`.
 */
export const PostAppointmentsBookings = (props: PostAppointmentsBookingsProps) => (
  <Mutate<PostAppointmentsBookingsResponse, BadRequestDetailResponse | void, void, BookingWrite, void>
    verb="POST"
    path={`/api/appointments/bookings`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UsePostAppointmentsBookingsProps = Omit<
  UseMutateProps<PostAppointmentsBookingsResponse, BadRequestDetailResponse | void, void, BookingWrite, void>,
  'path' | 'verb'
>;

/**
 * Creates a new appointments booking.
 *
 * The required fields for the registrationData can be retrieved from `/api/campaigns/{id}/appointments-registration-fields`.
 */
export const usePostAppointmentsBookings = (props: UsePostAppointmentsBookingsProps) =>
  useMutate<PostAppointmentsBookingsResponse, BadRequestDetailResponse | void, void, BookingWrite, void>(
    'POST',
    `/api/appointments/bookings`,
    {
      base: useAtmApiBaseUrl(),
      requestOptions: useAtmApiRequestOptions(false),
      ...props,
    },
  );

export interface GetBookingForCancelPathParams {
  id: string;
  cancellationKey: string;
}

export type GetBookingForCancelProps = Omit<GetProps<BookingRead, void, void, GetBookingForCancelPathParams>, 'path'> &
  GetBookingForCancelPathParams;

/**
 * Gets a booking, to review it before cancelling.
 */
export const GetBookingForCancel = ({ id, cancellationKey, ...props }: GetBookingForCancelProps) => (
  <Get<BookingRead, void, void, GetBookingForCancelPathParams>
    path={`/api/appointments/bookings/${id}/cancel/${cancellationKey}`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UseGetBookingForCancelProps = Omit<
  UseGetProps<BookingRead, void, void, GetBookingForCancelPathParams>,
  'path'
> &
  GetBookingForCancelPathParams;

/**
 * Gets a booking, to review it before cancelling.
 */
export const useGetBookingForCancel = ({ id, cancellationKey, ...props }: UseGetBookingForCancelProps) =>
  useGet<BookingRead, void, void, GetBookingForCancelPathParams>(
    (paramsInPath: GetBookingForCancelPathParams) =>
      `/api/appointments/bookings/${paramsInPath.id}/cancel/${paramsInPath.cancellationKey}`,
    {
      base: useAtmApiBaseUrl(),
      requestOptions: useAtmApiRequestOptions(false),
      pathParams: { id, cancellationKey },
      ...props,
    },
  );

export interface CancelBookingPathParams {
  id: string;
}

export type CancelBookingProps = Omit<MutateProps<void, void, void, string, CancelBookingPathParams>, 'path' | 'verb'> &
  CancelBookingPathParams;

/**
 * Cancels a booking.
 */
export const CancelBooking = ({ id, ...props }: CancelBookingProps) => (
  <Mutate<void, void, void, string, CancelBookingPathParams>
    verb="DELETE"
    path={`/api/appointments/bookings/${id}/cancel`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UseCancelBookingProps = Omit<
  UseMutateProps<void, void, void, string, CancelBookingPathParams>,
  'path' | 'verb'
> &
  CancelBookingPathParams;

/**
 * Cancels a booking.
 */
export const useCancelBooking = ({ id, ...props }: UseCancelBookingProps) =>
  useMutate<void, void, void, string, CancelBookingPathParams>(
    'DELETE',
    (paramsInPath: CancelBookingPathParams) => `/api/appointments/bookings/${paramsInPath.id}/cancel`,
    {
      base: useAtmApiBaseUrl(),
      requestOptions: useAtmApiRequestOptions(false),
      pathParams: { id },
      ...props,
    },
  );

export type PostReserveAppointmentProps = Omit<
  MutateProps<void, void, void, AppointmentReservationWrite, void>,
  'path' | 'verb'
>;

/**
 * Reserves an appointment for five minutes.
 */
export const PostReserveAppointment = (props: PostReserveAppointmentProps) => (
  <Mutate<void, void, void, AppointmentReservationWrite, void>
    verb="POST"
    path={`/api/appointments/reservations`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UsePostReserveAppointmentProps = Omit<
  UseMutateProps<void, void, void, AppointmentReservationWrite, void>,
  'path' | 'verb'
>;

/**
 * Reserves an appointment for five minutes.
 */
export const usePostReserveAppointment = (props: UsePostReserveAppointmentProps) =>
  useMutate<void, void, void, AppointmentReservationWrite, void>('POST', `/api/appointments/reservations`, {
    base: useAtmApiBaseUrl(),
    requestOptions: useAtmApiRequestOptions(false),
    ...props,
  });

export interface PostAppointmentsWaitingListEntriesResponse {
  success?: boolean;
  /**
   * The amount of errors. Check the error property of the fields if this is bigger than 0.
   */
  errorCount?: number;
  fields?: FormFieldsArray;
}

export type PostAppointmentsWaitingListEntriesProps = Omit<
  MutateProps<
    PostAppointmentsWaitingListEntriesResponse,
    BadRequestDetailResponse | void,
    void,
    AppointmentWaitingListEntryWrite,
    void
  >,
  'path' | 'verb'
>;

/**
 * Creates a new appointments waiting list entry.
 *
 * The required fields for the registrationData can be retrieved from `/api/campaigns/{id}/appointments-registration-fields`.
 */
export const PostAppointmentsWaitingListEntries = (props: PostAppointmentsWaitingListEntriesProps) => (
  <Mutate<
    PostAppointmentsWaitingListEntriesResponse,
    BadRequestDetailResponse | void,
    void,
    AppointmentWaitingListEntryWrite,
    void
  >
    verb="POST"
    path={`/api/appointments/waiting-list-entries`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UsePostAppointmentsWaitingListEntriesProps = Omit<
  UseMutateProps<
    PostAppointmentsWaitingListEntriesResponse,
    BadRequestDetailResponse | void,
    void,
    AppointmentWaitingListEntryWrite,
    void
  >,
  'path' | 'verb'
>;

/**
 * Creates a new appointments waiting list entry.
 *
 * The required fields for the registrationData can be retrieved from `/api/campaigns/{id}/appointments-registration-fields`.
 */
export const usePostAppointmentsWaitingListEntries = (props: UsePostAppointmentsWaitingListEntriesProps) =>
  useMutate<
    PostAppointmentsWaitingListEntriesResponse,
    BadRequestDetailResponse | void,
    void,
    AppointmentWaitingListEntryWrite,
    void
  >('POST', `/api/appointments/waiting-list-entries`, {
    base: useAtmApiBaseUrl(),
    requestOptions: useAtmApiRequestOptions(false),
    ...props,
  });

export interface GetCampaignCollectionPathParams {
  id: string;
}

export type GetCampaignCollectionProps = Omit<
  GetProps<CampaignCollectionCampaignCollectionRead, void, void, GetCampaignCollectionPathParams>,
  'path'
> &
  GetCampaignCollectionPathParams;

/**
 * Returns the campaign collection for the given id.
 */
export const GetCampaignCollection = ({ id, ...props }: GetCampaignCollectionProps) => (
  <Get<CampaignCollectionCampaignCollectionRead, void, void, GetCampaignCollectionPathParams>
    path={`/api/campaign-collections/${id}`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UseGetCampaignCollectionProps = Omit<
  UseGetProps<CampaignCollectionCampaignCollectionRead, void, void, GetCampaignCollectionPathParams>,
  'path'
> &
  GetCampaignCollectionPathParams;

/**
 * Returns the campaign collection for the given id.
 */
export const useGetCampaignCollection = ({ id, ...props }: UseGetCampaignCollectionProps) =>
  useGet<CampaignCollectionCampaignCollectionRead, void, void, GetCampaignCollectionPathParams>(
    (paramsInPath: GetCampaignCollectionPathParams) => `/api/campaign-collections/${paramsInPath.id}`,
    {
      base: useAtmApiBaseUrl(),
      requestOptions: useAtmApiRequestOptions(false),
      pathParams: { id },
      ...props,
    },
  );

export interface GetCampaignQueryParams {
  context: ('portal' | 'appointment-frontend' | 'registration-frontend')[];
}

export interface GetCampaignPathParams {
  id: string;
}

export type GetCampaignProps = Omit<
  GetProps<CampaignReadPortal, void, GetCampaignQueryParams, GetCampaignPathParams>,
  'path'
> &
  GetCampaignPathParams;

/**
 * Returns a single campaign.
 */
export const GetCampaign = ({ id, ...props }: GetCampaignProps) => (
  <Get<CampaignReadPortal, void, GetCampaignQueryParams, GetCampaignPathParams>
    path={`/api/campaigns/${id}`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UseGetCampaignProps = Omit<
  UseGetProps<CampaignReadPortal, void, GetCampaignQueryParams, GetCampaignPathParams>,
  'path'
> &
  GetCampaignPathParams;

/**
 * Returns a single campaign.
 */
export const useGetCampaign = ({ id, ...props }: UseGetCampaignProps) =>
  useGet<CampaignReadPortal, void, GetCampaignQueryParams, GetCampaignPathParams>(
    (paramsInPath: GetCampaignPathParams) => `/api/campaigns/${paramsInPath.id}`,
    {
      base: useAtmApiBaseUrl(),
      requestOptions: useAtmApiRequestOptions(false),
      pathParams: { id },
      ...props,
    },
  );

export interface ApiCampaignsAppointmentsGetSubresourcePathParams {
  id: string;
}

export type ApiCampaignsAppointmentsGetSubresourceProps = Omit<
  GetProps<AppointmentRead[], void, void, ApiCampaignsAppointmentsGetSubresourcePathParams>,
  'path'
> &
  ApiCampaignsAppointmentsGetSubresourcePathParams;

/**
 * Retrieves the collection of Appointment resources.
 */
export const ApiCampaignsAppointmentsGetSubresource = ({
  id,
  ...props
}: ApiCampaignsAppointmentsGetSubresourceProps) => (
  <Get<AppointmentRead[], void, void, ApiCampaignsAppointmentsGetSubresourcePathParams>
    path={`/api/campaigns/${id}/appointments`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UseApiCampaignsAppointmentsGetSubresourceProps = Omit<
  UseGetProps<AppointmentRead[], void, void, ApiCampaignsAppointmentsGetSubresourcePathParams>,
  'path'
> &
  ApiCampaignsAppointmentsGetSubresourcePathParams;

/**
 * Retrieves the collection of Appointment resources.
 */
export const useApiCampaignsAppointmentsGetSubresource = ({
  id,
  ...props
}: UseApiCampaignsAppointmentsGetSubresourceProps) =>
  useGet<AppointmentRead[], void, void, ApiCampaignsAppointmentsGetSubresourcePathParams>(
    (paramsInPath: ApiCampaignsAppointmentsGetSubresourcePathParams) =>
      `/api/campaigns/${paramsInPath.id}/appointments`,
    {
      base: useAtmApiBaseUrl(),
      requestOptions: useAtmApiRequestOptions(false),
      pathParams: { id },
      ...props,
    },
  );

export interface GetCampaignAppointmentsRegistrationFieldsResponse {
  fields?: FormFieldsArray;
}

export interface GetCampaignAppointmentsRegistrationFieldsPathParams {
  id: string;
}

export type GetCampaignAppointmentsRegistrationFieldsProps = Omit<
  GetProps<
    GetCampaignAppointmentsRegistrationFieldsResponse,
    void,
    void,
    GetCampaignAppointmentsRegistrationFieldsPathParams
  >,
  'path'
> &
  GetCampaignAppointmentsRegistrationFieldsPathParams;

/**
 * Returns the appointments registration fields for a campaign.
 */
export const GetCampaignAppointmentsRegistrationFields = ({
  id,
  ...props
}: GetCampaignAppointmentsRegistrationFieldsProps) => (
  <Get<
    GetCampaignAppointmentsRegistrationFieldsResponse,
    void,
    void,
    GetCampaignAppointmentsRegistrationFieldsPathParams
  >
    path={`/api/campaigns/${id}/appointments-registration-fields`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UseGetCampaignAppointmentsRegistrationFieldsProps = Omit<
  UseGetProps<
    GetCampaignAppointmentsRegistrationFieldsResponse,
    void,
    void,
    GetCampaignAppointmentsRegistrationFieldsPathParams
  >,
  'path'
> &
  GetCampaignAppointmentsRegistrationFieldsPathParams;

/**
 * Returns the appointments registration fields for a campaign.
 */
export const useGetCampaignAppointmentsRegistrationFields = ({
  id,
  ...props
}: UseGetCampaignAppointmentsRegistrationFieldsProps) =>
  useGet<
    GetCampaignAppointmentsRegistrationFieldsResponse,
    void,
    void,
    GetCampaignAppointmentsRegistrationFieldsPathParams
  >(
    (paramsInPath: GetCampaignAppointmentsRegistrationFieldsPathParams) =>
      `/api/campaigns/${paramsInPath.id}/appointments-registration-fields`,
    {
      base: useAtmApiBaseUrl(),
      requestOptions: useAtmApiRequestOptions(false),
      pathParams: { id },
      ...props,
    },
  );

export interface GetCampaignRegisterResponse {
  fields?: FormFieldsArray;
}

export interface GetCampaignRegisterQueryParams {
  context: ('portal' | 'registration-frontend')[];
  /**
   * If true, only the fields for the registration via login form will be returned.
   */
  forLogin?: boolean;
  /**
   * If your portal supports more than one language, provide the language selected by the user here. This language will then be used for translating the labels of the form fields. Currently only "de" and "en" are available, other values are ignored. Default is "de".
   */
  language?: string;
}

export interface GetCampaignRegisterPathParams {
  /**
   * The id of the campaign
   */
  id: string;
}

export type GetCampaignRegisterProps = Omit<
  GetProps<
    GetCampaignRegisterResponse,
    BadRequestResponse | UnauthorizedResponse,
    GetCampaignRegisterQueryParams,
    GetCampaignRegisterPathParams
  >,
  'path'
> &
  GetCampaignRegisterPathParams;

/**
 * Returns the fields of the register form for a campaign.
 */
export const GetCampaignRegister = ({ id, ...props }: GetCampaignRegisterProps) => (
  <Get<
    GetCampaignRegisterResponse,
    BadRequestResponse | UnauthorizedResponse,
    GetCampaignRegisterQueryParams,
    GetCampaignRegisterPathParams
  >
    path={`/api/campaigns/${id}/register`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UseGetCampaignRegisterProps = Omit<
  UseGetProps<
    GetCampaignRegisterResponse,
    BadRequestResponse | UnauthorizedResponse,
    GetCampaignRegisterQueryParams,
    GetCampaignRegisterPathParams
  >,
  'path'
> &
  GetCampaignRegisterPathParams;

/**
 * Returns the fields of the register form for a campaign.
 */
export const useGetCampaignRegister = ({ id, ...props }: UseGetCampaignRegisterProps) =>
  useGet<
    GetCampaignRegisterResponse,
    BadRequestResponse | UnauthorizedResponse,
    GetCampaignRegisterQueryParams,
    GetCampaignRegisterPathParams
  >((paramsInPath: GetCampaignRegisterPathParams) => `/api/campaigns/${paramsInPath.id}/register`, {
    base: useAtmApiBaseUrl(),
    requestOptions: useAtmApiRequestOptions(false),
    pathParams: { id },
    ...props,
  });

export interface PostCampaignRegisterResponse {
  success?: boolean;
  /**
   * The amount of errors. Check the error property of the fields if this is bigger than 0.
   */
  errorCount?: number;
  fields?: FormFieldsArray;
}

export interface PostCampaignRegisterQueryParams {
  context: ('portal' | 'registration-frontend')[];
  /**
   * If your portal supports more than one language, provide the language selected by the user here. This language will then be used for translating the error messages and as language preference for the mails sent to the user. Currently only "de" and "en" are available, other values are ignored. Default is "de".
   */
  language?: string;
}

export interface PostCampaignRegisterPathParams {
  /**
   * The id of the campaign
   */
  id: string;
}

export interface PostCampaignRegisterRequestBody {
  [key: string]: any;
}

export type PostCampaignRegisterProps = Omit<
  MutateProps<
    PostCampaignRegisterResponse,
    BadRequestResponse | UnauthorizedResponse,
    PostCampaignRegisterQueryParams,
    PostCampaignRegisterRequestBody,
    PostCampaignRegisterPathParams
  >,
  'path' | 'verb'
> &
  PostCampaignRegisterPathParams;

/**
 * Registers an attendee for a campaign.
 *
 * If the attendee doesn't exist yet, it will be created.
 */
export const PostCampaignRegister = ({ id, ...props }: PostCampaignRegisterProps) => (
  <Mutate<
    PostCampaignRegisterResponse,
    BadRequestResponse | UnauthorizedResponse,
    PostCampaignRegisterQueryParams,
    PostCampaignRegisterRequestBody,
    PostCampaignRegisterPathParams
  >
    verb="POST"
    path={`/api/campaigns/${id}/register`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UsePostCampaignRegisterProps = Omit<
  UseMutateProps<
    PostCampaignRegisterResponse,
    BadRequestResponse | UnauthorizedResponse,
    PostCampaignRegisterQueryParams,
    PostCampaignRegisterRequestBody,
    PostCampaignRegisterPathParams
  >,
  'path' | 'verb'
> &
  PostCampaignRegisterPathParams;

/**
 * Registers an attendee for a campaign.
 *
 * If the attendee doesn't exist yet, it will be created.
 */
export const usePostCampaignRegister = ({ id, ...props }: UsePostCampaignRegisterProps) =>
  useMutate<
    PostCampaignRegisterResponse,
    BadRequestResponse | UnauthorizedResponse,
    PostCampaignRegisterQueryParams,
    PostCampaignRegisterRequestBody,
    PostCampaignRegisterPathParams
  >('POST', (paramsInPath: PostCampaignRegisterPathParams) => `/api/campaigns/${paramsInPath.id}/register`, {
    base: useAtmApiBaseUrl(),
    requestOptions: useAtmApiRequestOptions(false),
    pathParams: { id },
    ...props,
  });

export interface PostContactResponse {
  success?: boolean;
}

export type PostContactProps = Omit<
  MutateProps<PostContactResponse, BadRequestDetailResponse, void, ContactRequest, void>,
  'path' | 'verb'
>;

/**
 * Sends a contact request via mail
 */
export const PostContact = (props: PostContactProps) => (
  <Mutate<PostContactResponse, BadRequestDetailResponse, void, ContactRequest, void>
    verb="POST"
    path={`/api/contact`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UsePostContactProps = Omit<
  UseMutateProps<PostContactResponse, BadRequestDetailResponse, void, ContactRequest, void>,
  'path' | 'verb'
>;

/**
 * Sends a contact request via mail
 */
export const usePostContact = (props: UsePostContactProps) =>
  useMutate<PostContactResponse, BadRequestDetailResponse, void, ContactRequest, void>('POST', `/api/contact`, {
    base: useAtmApiBaseUrl(),
    requestOptions: useAtmApiRequestOptions(false),
    ...props,
  });

export type GetInsurancesProps = Omit<GetProps<InsuranceReadInsurance[], unknown, void, void>, 'path'>;

/**
 * Returns a list of all insurance companies.
 */
export const GetInsurances = (props: GetInsurancesProps) => (
  <Get<InsuranceReadInsurance[], unknown, void, void>
    path={`/api/insurances`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UseGetInsurancesProps = Omit<UseGetProps<InsuranceReadInsurance[], unknown, void, void>, 'path'>;

/**
 * Returns a list of all insurance companies.
 */
export const useGetInsurances = (props: UseGetInsurancesProps) =>
  useGet<InsuranceReadInsurance[], unknown, void, void>(`/api/insurances`, {
    base: useAtmApiBaseUrl(),
    requestOptions: useAtmApiRequestOptions(false),
    ...props,
  });

export interface GetInsurancePathParams {
  id: string;
}

export type GetInsuranceProps = Omit<GetProps<InsuranceReadInsurance, void, void, GetInsurancePathParams>, 'path'> &
  GetInsurancePathParams;

/**
 * Returns a single insurance company.
 */
export const GetInsurance = ({ id, ...props }: GetInsuranceProps) => (
  <Get<InsuranceReadInsurance, void, void, GetInsurancePathParams>
    path={`/api/insurances/${id}`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UseGetInsuranceProps = Omit<
  UseGetProps<InsuranceReadInsurance, void, void, GetInsurancePathParams>,
  'path'
> &
  GetInsurancePathParams;

/**
 * Returns a single insurance company.
 */
export const useGetInsurance = ({ id, ...props }: UseGetInsuranceProps) =>
  useGet<InsuranceReadInsurance, void, void, GetInsurancePathParams>(
    (paramsInPath: GetInsurancePathParams) => `/api/insurances/${paramsInPath.id}`,
    {
      base: useAtmApiBaseUrl(),
      requestOptions: useAtmApiRequestOptions(false),
      pathParams: { id },
      ...props,
    },
  );

export interface PostResendActivationMailResponse {
  success?: boolean;
}

export type PostResendActivationMailProps = Omit<
  MutateProps<PostResendActivationMailResponse, BadRequestDetailResponse, void, ResendActivationMailRequest, void>,
  'path' | 'verb'
>;

/**
 * Resends the activation mail (containing the activation key).
 *
 * Resends the activation mail (containing the activation key).
 */
export const PostResendActivationMail = (props: PostResendActivationMailProps) => (
  <Mutate<PostResendActivationMailResponse, BadRequestDetailResponse, void, ResendActivationMailRequest, void>
    verb="POST"
    path={`/api/resend-activation-mail`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UsePostResendActivationMailProps = Omit<
  UseMutateProps<PostResendActivationMailResponse, BadRequestDetailResponse, void, ResendActivationMailRequest, void>,
  'path' | 'verb'
>;

/**
 * Resends the activation mail (containing the activation key).
 *
 * Resends the activation mail (containing the activation key).
 */
export const usePostResendActivationMail = (props: UsePostResendActivationMailProps) =>
  useMutate<PostResendActivationMailResponse, BadRequestDetailResponse, void, ResendActivationMailRequest, void>(
    'POST',
    `/api/resend-activation-mail`,
    {
      base: useAtmApiBaseUrl(),
      requestOptions: useAtmApiRequestOptions(false),
      ...props,
    },
  );

export interface PostRequestPasswordResetResponse {
  success?: boolean;
}

export type PostRequestPasswordResetProps = Omit<
  MutateProps<
    PostRequestPasswordResetResponse,
    BadRequestDetailResponse,
    void,
    ResetPasswordRequestRequestPasswordReset,
    void
  >,
  'path' | 'verb'
>;

/**
 * Starts the password reset process for an attendee.
 *
 * Sends a mail to the provided attendee email with a password reset link.
 * The link will point to your portal and contains a key that must be passed to the *[/reset-password/set](#/Public/postDoPasswordReset)* method. The key is urlencoded.
 *
 * The link format can be set by the SBK through the ATM backend.
 *
 */
export const PostRequestPasswordReset = (props: PostRequestPasswordResetProps) => (
  <Mutate<
    PostRequestPasswordResetResponse,
    BadRequestDetailResponse,
    void,
    ResetPasswordRequestRequestPasswordReset,
    void
  >
    verb="POST"
    path={`/api/reset-password`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UsePostRequestPasswordResetProps = Omit<
  UseMutateProps<
    PostRequestPasswordResetResponse,
    BadRequestDetailResponse,
    void,
    ResetPasswordRequestRequestPasswordReset,
    void
  >,
  'path' | 'verb'
>;

/**
 * Starts the password reset process for an attendee.
 *
 * Sends a mail to the provided attendee email with a password reset link.
 * The link will point to your portal and contains a key that must be passed to the *[/reset-password/set](#/Public/postDoPasswordReset)* method. The key is urlencoded.
 *
 * The link format can be set by the SBK through the ATM backend.
 *
 */
export const usePostRequestPasswordReset = (props: UsePostRequestPasswordResetProps) =>
  useMutate<
    PostRequestPasswordResetResponse,
    BadRequestDetailResponse,
    void,
    ResetPasswordRequestRequestPasswordReset,
    void
  >('POST', `/api/reset-password`, {
    base: useAtmApiBaseUrl(),
    requestOptions: useAtmApiRequestOptions(false),
    ...props,
  });

export interface PostCheckPasswordResetKeyResponse {
  success?: boolean;
}

export type PostCheckPasswordResetKeyProps = Omit<
  MutateProps<
    PostCheckPasswordResetKeyResponse,
    BadRequestDetailResponse,
    void,
    ResetPasswordRequestCheckPasswordResetKey,
    void
  >,
  'path' | 'verb'
>;

/**
 * Checks if a passsword reset key is valid.
 *
 * Requires the key generated by *[/api/reset-password](#/Public/postRequestPasswordReset)*.
 *
 */
export const PostCheckPasswordResetKey = (props: PostCheckPasswordResetKeyProps) => (
  <Mutate<
    PostCheckPasswordResetKeyResponse,
    BadRequestDetailResponse,
    void,
    ResetPasswordRequestCheckPasswordResetKey,
    void
  >
    verb="POST"
    path={`/api/reset-password/check`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UsePostCheckPasswordResetKeyProps = Omit<
  UseMutateProps<
    PostCheckPasswordResetKeyResponse,
    BadRequestDetailResponse,
    void,
    ResetPasswordRequestCheckPasswordResetKey,
    void
  >,
  'path' | 'verb'
>;

/**
 * Checks if a passsword reset key is valid.
 *
 * Requires the key generated by *[/api/reset-password](#/Public/postRequestPasswordReset)*.
 *
 */
export const usePostCheckPasswordResetKey = (props: UsePostCheckPasswordResetKeyProps) =>
  useMutate<
    PostCheckPasswordResetKeyResponse,
    BadRequestDetailResponse,
    void,
    ResetPasswordRequestCheckPasswordResetKey,
    void
  >('POST', `/api/reset-password/check`, {
    base: useAtmApiBaseUrl(),
    requestOptions: useAtmApiRequestOptions(false),
    ...props,
  });

export interface PostDoPasswordResetResponse {
  success?: boolean;
}

export type PostDoPasswordResetProps = Omit<
  MutateProps<PostDoPasswordResetResponse, BadRequestDetailResponse, void, ResetPasswordRequestDoPasswordReset, void>,
  'path' | 'verb'
>;

/**
 * Sets a new password for an attendee.
 *
 * Requires the key generated by *[/api/reset-password](#/Public/postRequestPasswordReset)*.
 *
 */
export const PostDoPasswordReset = (props: PostDoPasswordResetProps) => (
  <Mutate<PostDoPasswordResetResponse, BadRequestDetailResponse, void, ResetPasswordRequestDoPasswordReset, void>
    verb="POST"
    path={`/api/reset-password/set`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UsePostDoPasswordResetProps = Omit<
  UseMutateProps<
    PostDoPasswordResetResponse,
    BadRequestDetailResponse,
    void,
    ResetPasswordRequestDoPasswordReset,
    void
  >,
  'path' | 'verb'
>;

/**
 * Sets a new password for an attendee.
 *
 * Requires the key generated by *[/api/reset-password](#/Public/postRequestPasswordReset)*.
 *
 */
export const usePostDoPasswordReset = (props: UsePostDoPasswordResetProps) =>
  useMutate<PostDoPasswordResetResponse, BadRequestDetailResponse, void, ResetPasswordRequestDoPasswordReset, void>(
    'POST',
    `/api/reset-password/set`,
    {
      base: useAtmApiBaseUrl(),
      requestOptions: useAtmApiRequestOptions(false),
      ...props,
    },
  );

export type GetSitesProps = Omit<GetProps<SiteReadCollection[], unknown, void, void>, 'path'>;

/**
 * Returns a list of all available sites.
 */
export const GetSites = (props: GetSitesProps) => (
  <Get<SiteReadCollection[], unknown, void, void>
    path={`/api/sites`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UseGetSitesProps = Omit<UseGetProps<SiteReadCollection[], unknown, void, void>, 'path'>;

/**
 * Returns a list of all available sites.
 */
export const useGetSites = (props: UseGetSitesProps) =>
  useGet<SiteReadCollection[], unknown, void, void>(`/api/sites`, {
    base: useAtmApiBaseUrl(),
    requestOptions: useAtmApiRequestOptions(false),
    ...props,
  });

export interface GetSitePathParams {
  name: string;
}

export type GetSiteProps = Omit<GetProps<SiteReadItem, void, void, GetSitePathParams>, 'path'> & GetSitePathParams;

/**
 * Returns a single site.
 */
export const GetSite = ({ name, ...props }: GetSiteProps) => (
  <Get<SiteReadItem, void, void, GetSitePathParams>
    path={`/api/sites/${name}`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UseGetSiteProps = Omit<UseGetProps<SiteReadItem, void, void, GetSitePathParams>, 'path'> &
  GetSitePathParams;

/**
 * Returns a single site.
 */
export const useGetSite = ({ name, ...props }: UseGetSiteProps) =>
  useGet<SiteReadItem, void, void, GetSitePathParams>(
    (paramsInPath: GetSitePathParams) => `/api/sites/${paramsInPath.name}`,
    {
      base: useAtmApiBaseUrl(),
      requestOptions: useAtmApiRequestOptions(false),
      pathParams: { name },
      ...props,
    },
  );

export interface GetJoinTeamPathParams {
  joinKey: string;
}

export type GetJoinTeamProps = Omit<
  GetProps<JoinTeamRequestRead, BadRequestDetailResponse | void, void, GetJoinTeamPathParams>,
  'path'
> &
  GetJoinTeamPathParams;

/**
 * Gets the campaign id and invited email for a team join key.
 */
export const GetJoinTeam = ({ joinKey, ...props }: GetJoinTeamProps) => (
  <Get<JoinTeamRequestRead, BadRequestDetailResponse | void, void, GetJoinTeamPathParams>
    path={`/api/team/join/${joinKey}`}
    base={useAtmApiBaseUrl()}
    requestOptions={useAtmApiRequestOptions(false)}
    {...props}
  />
);

export type UseGetJoinTeamProps = Omit<
  UseGetProps<JoinTeamRequestRead, BadRequestDetailResponse | void, void, GetJoinTeamPathParams>,
  'path'
> &
  GetJoinTeamPathParams;

/**
 * Gets the campaign id and invited email for a team join key.
 */
export const useGetJoinTeam = ({ joinKey, ...props }: UseGetJoinTeamProps) =>
  useGet<JoinTeamRequestRead, BadRequestDetailResponse | void, void, GetJoinTeamPathParams>(
    (paramsInPath: GetJoinTeamPathParams) => `/api/team/join/${paramsInPath.joinKey}`,
    {
      base: useAtmApiBaseUrl(),
      requestOptions: useAtmApiRequestOptions(false),
      pathParams: { joinKey },
      ...props,
    },
  );
