import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import DocumentTitle from '../routing/DocumentTitle';

const Breadcrumbs = ({ breadcrumbs, pageTitle }) => {
  const breadcrumbList = breadcrumbs
    ? breadcrumbs.map((breadcrumb) => (
        <li
          key={breadcrumb.title}
          className="breadcrumbs__item"
        >
          <NavLink
            to={breadcrumb.link}
            className="breadcrumbs__link button_type_link"
          >
            {breadcrumb.title}
          </NavLink>
        </li>
      ))
    : null;
  return (
    <div className="breadcrumbs">
      <DocumentTitle>{pageTitle}</DocumentTitle>
      <Grid fluid={true}>
        <ul className="breadcrumbs__list">
          <li className="breadcrumbs__item">
            <NavLink
              to="/"
              className="breadcrumbs__link button_type_link"
            >
              Startseite
            </NavLink>
          </li>
          {breadcrumbs && breadcrumbList}
          {pageTitle && <li className="breadcrumbs__item">{pageTitle}</li>}
        </ul>
      </Grid>
    </div>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
};

export default Breadcrumbs;
