import React from 'react';
import { Grid } from 'react-bootstrap';
import DocumentTitle from '../routing/DocumentTitle';
import Wrapper from '../layout/Wrapper';
import Breadcrumbs from '../layout/Breadcrumbs';

export default () => {
  return (
    <Wrapper>
      <DocumentTitle>Seite nicht gefunden</DocumentTitle>
      <Breadcrumbs />
      <Grid fluid={true}>
        <h1>Seite nicht gefunden</h1>
        <p>Leider konnte die von Ihnen aufgerufene Seite nicht gefunden werden.</p>
      </Grid>
    </Wrapper>
  );
};
