import React from 'react';
import { useLoginToken } from './ApiProvider';

export default ({ children, ...props }) => {
  const token = useLoginToken();

  return React.cloneElement(children, {
    ...props,
    isLoggedIn: token !== null,
  });
};
