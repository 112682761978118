import React, { createContext, useContext, useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';

import './Loader.scss';

const LoaderControlContext = createContext();

export const LoaderWrapper = ({ overlay }) => {
  const [spinnerVisible, setSpinnerVisible] = useState(false);

  useEffect(() => {
    // Wait 300ms on mount to show the spinner
    const timeout = setTimeout(() => setSpinnerVisible(true), 300);

    // If the element gets unmounted in under 300ms, don't show the spinner
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={'loader-wrapper' + (overlay ? ' loader-overlay' : '')}>
      <TailSpin
        color="#003C6E"
        visible={spinnerVisible}
        wrapperClass="loader"
      />
    </div>
  );
};

export const LoaderOverlayProvider = ({ children }) => {
  const [loaderCount, setLoaderCount] = useState(0);

  const addLoader = () => setLoaderCount(loaderCount + 1);
  const removeLoader = () => setLoaderCount(Math.max(loaderCount - 1, 0));

  return (
    <LoaderControlContext.Provider value={{ addLoader, removeLoader }}>
      {loaderCount > 0 && <LoaderWrapper overlay={true} />}

      {children}
    </LoaderControlContext.Provider>
  );
};

export default ({ overlay = true }) => {
  if (!overlay) {
    return <LoaderWrapper overlay={false} />;
  }

  const { addLoader, removeLoader } = useContext(LoaderControlContext);

  useEffect(() => {
    // Add loader on mount
    addLoader();

    // Remove loader on unmont
    return () => removeLoader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
