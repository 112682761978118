import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

const SocialLinkList = [
  {
    id: 1,
    name: 'facebook',
    link: 'https://www.facebook.com/SBK',
  },
  {
    id: 2,
    name: 'twitter',
    link: 'https://twitter.com/Siemens_BKK',
  },
  {
    id: 3,
    name: 'xing',
    link: 'https://www.xing.com/companies/sbk-siemens-betriebskrankenkasse',
  },
  {
    id: 4,
    name: 'youtube',
    link: 'https://www.youtube.com/user/SiemensBKK',
  },
];

const SocialLinks = SocialLinkList.map((SocialLink) => (
  <React.Fragment key={SocialLink.id}>
    {' '}
    <li className="pre-footer__social-item">
      <a
        target="_blank"
        rel="noopener noreferrer"
        className={'pre-footer__social-link pre-footer__social-link_' + SocialLink.name}
        href={SocialLink.link}
      >
        <span className="sr-only">{SocialLink.name.charAt(0).toUpperCase() + SocialLink.name.slice(1)}</span>
      </a>
    </li>
  </React.Fragment>
));

const PreFooter = () => {
  return (
    <div className="pre-footer">
      <Grid fluid={true}>
        <Row>
          <Col
            lg={5}
            md={6}
          >
            <h4 className="pre-footer__title">Ihre SBK</h4>
            <div>
              Die SBK (Siemens-Betriebskrankenkasse) ist mit mehr als 1 Million Versicherten eine der größten
              Betriebskrankenkassen Deutschlands. Sie ist eine geöffnete, bundesweit tätige gesetzliche Krankenkasse und
              wurde mehrfach für ihre Kundenfreundlichkeit und hervorragenden Leistungen ausgezeichnet.
            </div>
          </Col>
          <Col
            lg={5}
            md={6}
          >
            <h4 className="pre-footer__title">Kontaktieren Sie uns!</h4>
            <div>
              <div className="pre-footer__line">
                <span className="pre-footer__label">Web</span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pre-footer__link"
                  href="http://www.sbk.org"
                >
                  www.sbk.org
                </a>
              </div>
              <div className="pre-footer__line">
                <span className="pre-footer__label">E-Mail</span>
                <a
                  className="pre-footer__link"
                  href="mailto:trinkdichfit@sbk.org"
                >
                  trinkdichfit@sbk.org
                </a>
              </div>
            </div>
          </Col>
          <Col
            lg={2}
            md={12}
          >
            <h4 className="pre-footer__title">Folgen Sie uns!</h4>
            <ul className="pre-footer__social">{SocialLinks}</ul>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default PreFooter;
