/* Generated by restful-react */

import React from 'react';
import { Get, GetProps, useGet, UseGetProps, Mutate, MutateProps, useMutate, UseMutateProps } from 'restful-react';

import { useLoginToken } from './ApiProvider';
export const SPEC_VERSION = '1.0.0-dev';
export interface DayRead {
  id?: number;
  date?: string;
  waterAmount?: number;
}

export interface DayWrite {
  waterAmount?: number;
}

export interface DayJsonldRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        'hydra': 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  'id'?: number;
  'date'?: string;
  'waterAmount'?: number;
}

export interface DiaryRead {
  start?: string;
  end?: string;
  /**
   * Days that belong to the current user and this diary. Get loaded via the doctrine postLoad event in the
   * {@see SetDiaryDaysSubscriber}.
   */
  days?: string[];
  /**
   * Virtual method that returns the average water amount drank by the user since the start of the diary until today
   * or the end of the diary.
   */
  averageWaterAmount?: number;
}

export interface DiaryCreateDiaryRequest {
  /**
   * How many days should this diary last?
   */
  duration?: 14 | 21 | 28;
}

export interface DiaryCreateDiaryRequestJsonld {
  /**
   * How many days should this diary last?
   */
  duration?: 14 | 21 | 28;
}

export interface DiaryJsonldRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        'hydra': 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  'start'?: string;
  'end'?: string;
  /**
   * Days that belong to the current user and this diary. Get loaded via the doctrine postLoad event in the
   * {@see SetDiaryDaysSubscriber}.
   */
  'days'?: string[];
  /**
   * Virtual method that returns the average water amount drank by the user since the start of the diary until today
   * or the end of the diary.
   */
  'averageWaterAmount'?: number;
}

export interface Ranking {
  title?: string;
  entries?: RankingEntry[];
}

export interface RankingJsonld {
  '@id'?: string;
  '@type'?: string;
  'title'?: string;
  'entries'?: RankingEntryJsonld[];
}

export interface RankingEntry {
  place?: number;
  title?: string;
  waterAmount?: number;
}

export interface RankingEntryJsonld {
  '@context'?:
    | string
    | {
        '@vocab': string;
        'hydra': 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  'place'?: number;
  'title'?: string;
  'waterAmount'?: number;
}

export interface TeamDayRead {
  date?: string;
  waterAmount?: number;
  memberWaterAmount?: string[];
}

export interface TeamDayJsonldRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        'hydra': 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  'date'?: string;
  'waterAmount'?: number;
  'memberWaterAmount'?: string[];
}

export interface TeamDiaryRead {
  teamName?: string;
  /**
   * Holds the water amount values for the whole team per day. Gets loaded via the doctrine postLoad event in the
   * {@see SetTeamDaysSubscriber}.
   */
  teamDays?: TeamDayRead[];
  start?: string;
  end?: string;
  /**
   * Days that belong to the current user and this diary. Get loaded via the doctrine postLoad event in the
   * {@see SetDiaryDaysSubscriber}.
   */
  days?: string[];
  /**
   * Virtual method that returns the average water amount drank by the user since the start of the diary until today
   * or the end of the diary.
   */
  averageWaterAmount?: number;
}

export interface TeamDiaryCreateTeamDiaryRequest {
  /**
   * How many days should this diary last?
   */
  duration?: 14 | 21 | 28;
  /**
   * In how many days should the team diary start.
   */
  startInDays?: number;
}

export interface TeamDiaryCreateTeamDiaryRequestJsonld {
  /**
   * How many days should this diary last?
   */
  duration?: 14 | 21 | 28;
  /**
   * In how many days should the team diary start.
   */
  startInDays?: number;
}

export interface TeamDiaryJsonldRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        'hydra': 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  'teamName'?: string;
  /**
   * Holds the water amount values for the whole team per day. Gets loaded via the doctrine postLoad event in the
   * {@see SetTeamDaysSubscriber}.
   */
  'teamDays'?: TeamDayJsonldRead[];
  'start'?: string;
  'end'?: string;
  /**
   * Days that belong to the current user and this diary. Get loaded via the doctrine postLoad event in the
   * {@see SetDiaryDaysSubscriber}.
   */
  'days'?: string[];
  /**
   * Virtual method that returns the average water amount drank by the user since the start of the diary until today
   * or the end of the diary.
   */
  'averageWaterAmount'?: number;
}

export interface TokenRead {
  token?: string;
  atmApiBaseUrl?: string;
}

export interface TokenJsonldRead {
  '@context'?:
    | string
    | {
        '@vocab': string;
        'hydra': 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  'token'?: string;
  'atmApiBaseUrl'?: string;
}

export interface UserSettings {
  sendReminders?: boolean;
  showInRankings?: boolean;
}

export interface UserJsonldSettings {
  '@context'?:
    | string
    | {
        '@vocab': string;
        'hydra': 'http://www.w3.org/ns/hydra/core#';
        [key: string]: any;
      };
  '@id'?: string;
  '@type'?: string;
  'sendReminders'?: boolean;
  'showInRankings'?: boolean;
}

export interface GetDayPathParams {
  /**
   * Day identifier
   */
  id: string;
}

export type GetDayProps = Omit<GetProps<DayRead, void, void, GetDayPathParams>, 'path'> & GetDayPathParams;

/**
 * Retrieves a Day resource.
 *
 * Retrieves a Day resource.
 */
export const GetDay = ({ id, ...props }: GetDayProps) => (
  <Get<DayRead, void, void, GetDayPathParams>
    path={`/days/${id}`}
    base={process.env.REACT_APP_API_ENTRYPOINT}
    requestOptions={((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken())}
    {...props}
  />
);

export type UseGetDayProps = Omit<UseGetProps<DayRead, void, void, GetDayPathParams>, 'path'> & GetDayPathParams;

/**
 * Retrieves a Day resource.
 *
 * Retrieves a Day resource.
 */
export const useGetDay = ({ id, ...props }: UseGetDayProps) =>
  useGet<DayRead, void, void, GetDayPathParams>((paramsInPath: GetDayPathParams) => `/days/${paramsInPath.id}`, {
    base: process.env.REACT_APP_API_ENTRYPOINT,
    requestOptions: ((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken()),
    pathParams: { id },
    ...props,
  });

export interface UpdateDayPathParams {
  /**
   * Day identifier
   */
  id: string;
}

export type UpdateDayProps = Omit<MutateProps<DayRead, void, void, DayWrite, UpdateDayPathParams>, 'path' | 'verb'> &
  UpdateDayPathParams;

/**
 * Updates the Day resource.
 *
 * Updates the Day resource.
 */
export const UpdateDay = ({ id, ...props }: UpdateDayProps) => (
  <Mutate<DayRead, void, void, DayWrite, UpdateDayPathParams>
    verb="PATCH"
    path={`/days/${id}`}
    base={process.env.REACT_APP_API_ENTRYPOINT}
    requestOptions={((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken())}
    {...props}
  />
);

export type UseUpdateDayProps = Omit<
  UseMutateProps<DayRead, void, void, DayWrite, UpdateDayPathParams>,
  'path' | 'verb'
> &
  UpdateDayPathParams;

/**
 * Updates the Day resource.
 *
 * Updates the Day resource.
 */
export const useUpdateDay = ({ id, ...props }: UseUpdateDayProps) =>
  useMutate<DayRead, void, void, DayWrite, UpdateDayPathParams>(
    'PATCH',
    (paramsInPath: UpdateDayPathParams) => `/days/${paramsInPath.id}`,
    {
      base: process.env.REACT_APP_API_ENTRYPOINT,
      requestOptions: ((loginToken) => ({
        headers: {
          Authorization: 'Bearer ' + loginToken,
          Accept: 'application/json',
        },
      }))(useLoginToken()),
      pathParams: { id },
      ...props,
    },
  );

export interface GetDiariesQueryParams {
  /**
   * The collection page number
   */
  page?: number;
}

export type GetDiariesProps = Omit<GetProps<DiaryRead[], unknown, GetDiariesQueryParams, void>, 'path'>;

/**
 * Retrieves the collection of Diary resources.
 *
 * Retrieves the collection of Diary resources.
 */
export const GetDiaries = (props: GetDiariesProps) => (
  <Get<DiaryRead[], unknown, GetDiariesQueryParams, void>
    path={`/diaries`}
    base={process.env.REACT_APP_API_ENTRYPOINT}
    requestOptions={((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken())}
    {...props}
  />
);

export type UseGetDiariesProps = Omit<UseGetProps<DiaryRead[], unknown, GetDiariesQueryParams, void>, 'path'>;

/**
 * Retrieves the collection of Diary resources.
 *
 * Retrieves the collection of Diary resources.
 */
export const useGetDiaries = (props: UseGetDiariesProps) =>
  useGet<DiaryRead[], unknown, GetDiariesQueryParams, void>(`/diaries`, {
    base: process.env.REACT_APP_API_ENTRYPOINT,
    requestOptions: ((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken()),
    ...props,
  });

export type CreateDiaryProps = Omit<MutateProps<DiaryRead, void, void, DiaryCreateDiaryRequest, void>, 'path' | 'verb'>;

/**
 * Creates a Diary resource.
 *
 * Creates a Diary resource.
 */
export const CreateDiary = (props: CreateDiaryProps) => (
  <Mutate<DiaryRead, void, void, DiaryCreateDiaryRequest, void>
    verb="POST"
    path={`/diaries`}
    base={process.env.REACT_APP_API_ENTRYPOINT}
    requestOptions={((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken())}
    {...props}
  />
);

export type UseCreateDiaryProps = Omit<
  UseMutateProps<DiaryRead, void, void, DiaryCreateDiaryRequest, void>,
  'path' | 'verb'
>;

/**
 * Creates a Diary resource.
 *
 * Creates a Diary resource.
 */
export const useCreateDiary = (props: UseCreateDiaryProps) =>
  useMutate<DiaryRead, void, void, DiaryCreateDiaryRequest, void>('POST', `/diaries`, {
    base: process.env.REACT_APP_API_ENTRYPOINT,
    requestOptions: ((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken()),
    ...props,
  });

export interface GetDiaryPathParams {
  /**
   * Diary identifier
   */
  id: string;
}

export type GetDiaryProps = Omit<GetProps<DiaryRead, void, void, GetDiaryPathParams>, 'path'> & GetDiaryPathParams;

/**
 * Retrieves a Diary resource.
 *
 * Retrieves a Diary resource.
 */
export const GetDiary = ({ id, ...props }: GetDiaryProps) => (
  <Get<DiaryRead, void, void, GetDiaryPathParams>
    path={`/diaries/${id}`}
    base={process.env.REACT_APP_API_ENTRYPOINT}
    requestOptions={((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken())}
    {...props}
  />
);

export type UseGetDiaryProps = Omit<UseGetProps<DiaryRead, void, void, GetDiaryPathParams>, 'path'> &
  GetDiaryPathParams;

/**
 * Retrieves a Diary resource.
 *
 * Retrieves a Diary resource.
 */
export const useGetDiary = ({ id, ...props }: UseGetDiaryProps) =>
  useGet<DiaryRead, void, void, GetDiaryPathParams>(
    (paramsInPath: GetDiaryPathParams) => `/diaries/${paramsInPath.id}`,
    {
      base: process.env.REACT_APP_API_ENTRYPOINT,
      requestOptions: ((loginToken) => ({
        headers: {
          Authorization: 'Bearer ' + loginToken,
          Accept: 'application/json',
        },
      }))(useLoginToken()),
      pathParams: { id },
      ...props,
    },
  );

export interface GetRankingsQueryParams {
  /**
   * The collection page number
   */
  page?: number;
}

export type GetRankingsProps = Omit<GetProps<Ranking[], unknown, GetRankingsQueryParams, void>, 'path'>;

/**
 * Retrieves the collection of Ranking resources.
 *
 * Retrieves the collection of Ranking resources.
 */
export const GetRankings = (props: GetRankingsProps) => (
  <Get<Ranking[], unknown, GetRankingsQueryParams, void>
    path={`/rankings`}
    base={process.env.REACT_APP_API_ENTRYPOINT}
    requestOptions={((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken())}
    {...props}
  />
);

export type UseGetRankingsProps = Omit<UseGetProps<Ranking[], unknown, GetRankingsQueryParams, void>, 'path'>;

/**
 * Retrieves the collection of Ranking resources.
 *
 * Retrieves the collection of Ranking resources.
 */
export const useGetRankings = (props: UseGetRankingsProps) =>
  useGet<Ranking[], unknown, GetRankingsQueryParams, void>(`/rankings`, {
    base: process.env.REACT_APP_API_ENTRYPOINT,
    requestOptions: ((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken()),
    ...props,
  });

export interface GetTeamDiariesQueryParams {
  /**
   * The collection page number
   */
  page?: number;
}

export type GetTeamDiariesProps = Omit<GetProps<TeamDiaryRead[], unknown, GetTeamDiariesQueryParams, void>, 'path'>;

/**
 * Retrieves the collection of TeamDiary resources.
 *
 * Retrieves the collection of TeamDiary resources.
 */
export const GetTeamDiaries = (props: GetTeamDiariesProps) => (
  <Get<TeamDiaryRead[], unknown, GetTeamDiariesQueryParams, void>
    path={`/team_diaries`}
    base={process.env.REACT_APP_API_ENTRYPOINT}
    requestOptions={((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken())}
    {...props}
  />
);

export type UseGetTeamDiariesProps = Omit<
  UseGetProps<TeamDiaryRead[], unknown, GetTeamDiariesQueryParams, void>,
  'path'
>;

/**
 * Retrieves the collection of TeamDiary resources.
 *
 * Retrieves the collection of TeamDiary resources.
 */
export const useGetTeamDiaries = (props: UseGetTeamDiariesProps) =>
  useGet<TeamDiaryRead[], unknown, GetTeamDiariesQueryParams, void>(`/team_diaries`, {
    base: process.env.REACT_APP_API_ENTRYPOINT,
    requestOptions: ((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken()),
    ...props,
  });

export type CreateTeamDiaryProps = Omit<
  MutateProps<TeamDiaryRead, void, void, TeamDiaryCreateTeamDiaryRequest, void>,
  'path' | 'verb'
>;

/**
 * Creates a TeamDiary resource.
 *
 * Creates a TeamDiary resource.
 */
export const CreateTeamDiary = (props: CreateTeamDiaryProps) => (
  <Mutate<TeamDiaryRead, void, void, TeamDiaryCreateTeamDiaryRequest, void>
    verb="POST"
    path={`/team_diaries`}
    base={process.env.REACT_APP_API_ENTRYPOINT}
    requestOptions={((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken())}
    {...props}
  />
);

export type UseCreateTeamDiaryProps = Omit<
  UseMutateProps<TeamDiaryRead, void, void, TeamDiaryCreateTeamDiaryRequest, void>,
  'path' | 'verb'
>;

/**
 * Creates a TeamDiary resource.
 *
 * Creates a TeamDiary resource.
 */
export const useCreateTeamDiary = (props: UseCreateTeamDiaryProps) =>
  useMutate<TeamDiaryRead, void, void, TeamDiaryCreateTeamDiaryRequest, void>('POST', `/team_diaries`, {
    base: process.env.REACT_APP_API_ENTRYPOINT,
    requestOptions: ((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken()),
    ...props,
  });

export interface GetTeamDiaryPathParams {
  /**
   * TeamDiary identifier
   */
  id: string;
}

export type GetTeamDiaryProps = Omit<GetProps<TeamDiaryRead, void, void, GetTeamDiaryPathParams>, 'path'> &
  GetTeamDiaryPathParams;

/**
 * Retrieves a TeamDiary resource.
 *
 * Retrieves a TeamDiary resource.
 */
export const GetTeamDiary = ({ id, ...props }: GetTeamDiaryProps) => (
  <Get<TeamDiaryRead, void, void, GetTeamDiaryPathParams>
    path={`/team_diaries/${id}`}
    base={process.env.REACT_APP_API_ENTRYPOINT}
    requestOptions={((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken())}
    {...props}
  />
);

export type UseGetTeamDiaryProps = Omit<UseGetProps<TeamDiaryRead, void, void, GetTeamDiaryPathParams>, 'path'> &
  GetTeamDiaryPathParams;

/**
 * Retrieves a TeamDiary resource.
 *
 * Retrieves a TeamDiary resource.
 */
export const useGetTeamDiary = ({ id, ...props }: UseGetTeamDiaryProps) =>
  useGet<TeamDiaryRead, void, void, GetTeamDiaryPathParams>(
    (paramsInPath: GetTeamDiaryPathParams) => `/team_diaries/${paramsInPath.id}`,
    {
      base: process.env.REACT_APP_API_ENTRYPOINT,
      requestOptions: ((loginToken) => ({
        headers: {
          Authorization: 'Bearer ' + loginToken,
          Accept: 'application/json',
        },
      }))(useLoginToken()),
      pathParams: { id },
      ...props,
    },
  );

export type GetTokenProps = Omit<GetProps<TokenRead, void, void, void>, 'path'>;

/**
 * Retrieves a Token resource.
 *
 * Retrieves a Token resource.
 */
export const GetToken = (props: GetTokenProps) => (
  <Get<TokenRead, void, void, void>
    path={`/token`}
    base={process.env.REACT_APP_API_ENTRYPOINT}
    requestOptions={((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken())}
    {...props}
  />
);

export type UseGetTokenProps = Omit<UseGetProps<TokenRead, void, void, void>, 'path'>;

/**
 * Retrieves a Token resource.
 *
 * Retrieves a Token resource.
 */
export const useGetToken = (props: UseGetTokenProps) =>
  useGet<TokenRead, void, void, void>(`/token`, {
    base: process.env.REACT_APP_API_ENTRYPOINT,
    requestOptions: ((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken()),
    ...props,
  });

export type GetUserProps = Omit<GetProps<UserSettings, void, void, void>, 'path'>;

/**
 * Retrieves a User resource.
 *
 * Retrieves a User resource.
 */
export const GetUser = (props: GetUserProps) => (
  <Get<UserSettings, void, void, void>
    path={`/user`}
    base={process.env.REACT_APP_API_ENTRYPOINT}
    requestOptions={((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken())}
    {...props}
  />
);

export type UseGetUserProps = Omit<UseGetProps<UserSettings, void, void, void>, 'path'>;

/**
 * Retrieves a User resource.
 *
 * Retrieves a User resource.
 */
export const useGetUser = (props: UseGetUserProps) =>
  useGet<UserSettings, void, void, void>(`/user`, {
    base: process.env.REACT_APP_API_ENTRYPOINT,
    requestOptions: ((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken()),
    ...props,
  });

export type UpdateUserProps = Omit<MutateProps<UserSettings, void, void, UserSettings, void>, 'path' | 'verb'>;

/**
 * Updates the User resource.
 *
 * Updates the User resource.
 */
export const UpdateUser = (props: UpdateUserProps) => (
  <Mutate<UserSettings, void, void, UserSettings, void>
    verb="PATCH"
    path={`/user`}
    base={process.env.REACT_APP_API_ENTRYPOINT}
    requestOptions={((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken())}
    {...props}
  />
);

export type UseUpdateUserProps = Omit<UseMutateProps<UserSettings, void, void, UserSettings, void>, 'path' | 'verb'>;

/**
 * Updates the User resource.
 *
 * Updates the User resource.
 */
export const useUpdateUser = (props: UseUpdateUserProps) =>
  useMutate<UserSettings, void, void, UserSettings, void>('PATCH', `/user`, {
    base: process.env.REACT_APP_API_ENTRYPOINT,
    requestOptions: ((loginToken) => ({
      headers: {
        Authorization: 'Bearer ' + loginToken,
        Accept: 'application/json',
      },
    }))(useLoginToken()),
    ...props,
  });
