import React, { createContext, ReactNode, useContext, useState } from 'react';
import { RestfulProvider } from 'restful-react';
import { Grid } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode';
import { TokenRead, useGetToken } from './tdf';
import Loader from '../components/Loader';

interface DecodedJWT {
  exp: number;
  [key: string]: any;
}

interface ApiConfig {
  apiError: boolean;
  setApiError: (apiError: boolean) => void;
  atmApiBaseUrl?: string;
  setAtmApiBaseUrl: (atmApiBaseUrl: string | undefined) => void;
  loginToken: string | null;
  setLoginToken: (token: string | null) => void;
}

// Store api token globally and clear it when it expires
let token: string | null;
let refetchTokenTimeout: number;
let refetchTokenPromise: Promise<void>;
function setToken(newToken: string | null) {
  token = newToken;

  if (token) {
    localStorage.setItem('token', token);

    // Set clear timeout
    const expiresIn = jwtDecode<DecodedJWT>(token).exp * 1000 - Date.now();
    if (expiresIn <= 0) {
      // If it is already expired, recall the current method and set the token to null
      setToken(null);
    } else {
      // Else wait for it to expire
      window.clearTimeout(refetchTokenTimeout);
      refetchTokenTimeout = window.setTimeout(() => setToken(null), expiresIn);
    }
  } else {
    window.clearTimeout(refetchTokenTimeout);
    localStorage.removeItem('token');
  }
}

// Set initial token with the value saved in the session
setToken(localStorage.getItem('token'));

// Store api config in context
const ApiContext = createContext({} as ApiConfig);

// Hooks to quickly access the ApiContext values
export const useAtmApiBaseUrl = () => useContext(ApiContext).atmApiBaseUrl;
export const useLoginToken = () => useContext(ApiContext).loginToken;
export const useSetLoginToken = () => useContext(ApiContext).setLoginToken;

export const useAtmApiRequestOptions = (requiresDetailAuthorization: boolean) => {
  const { loginToken, setAtmApiBaseUrl, setApiError } = useContext(ApiContext);

  const headers: HeadersInit = {};

  if (requiresDetailAuthorization && loginToken) {
    headers['Detail-Authorization'] = 'Bearer ' + loginToken;
  }

  // Return async function to fetch token right before the actual request
  return async () => {
    if (refetchTokenPromise) {
      // If there is already an ongoing refetch, wait for it
      await refetchTokenPromise;
    }

    if (!token) {
      // Set global promise so all other requests wait for us
      refetchTokenPromise = new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(process.env.REACT_APP_API_ENTRYPOINT + '/token');
          const { token, atmApiBaseUrl } = (await response.json()) as TokenRead;

          setAtmApiBaseUrl(atmApiBaseUrl);
          setToken(token || null);

          resolve();
        } catch (error) {
          setApiError(true);

          reject(error);
        }
      });
      await refetchTokenPromise;
    }

    headers['Authorization'] = 'Bearer ' + token;

    return {
      headers,
    };
  };
};

const ApiProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [apiError, setApiError] = useState(false);
  const [atmApiBaseUrl, stateSetAtmApiBaseUrl] = useState(localStorage.getItem('atmApiBaseUrl') || undefined);
  const [loginToken, stateSetLoginToken] = useState(localStorage.getItem('loginToken'));
  let { data, loading, error, refetch } = useGetToken({
    lazy: true, // Only get a new token if we call refetch()
  });

  if (error || apiError) {
    return (
      <Grid fluid={true}>
        <h1>Fehler bei der Kommunikation mit der SBK-API</h1>
        <p>Wir haben momentan Probleme die gewünschten Daten abzurufen, bitte versuchen Sie es später noch einmal.</p>
      </Grid>
    );
  }

  // If no atm base url is saved, get it before doing anything else
  if (!atmApiBaseUrl) {
    if (!loading) {
      refetch();
    }

    if (data && data.token && data.atmApiBaseUrl) {
      setToken(data.token);
      setAtmApiBaseUrl(data.atmApiBaseUrl);
    }

    return <Loader />;
  }

  // When setting the atmApiBaseUrl or the loginToken, also set it in / remove it from the session storage
  function setAtmApiBaseUrl(atmApiBaseUrl: string | undefined): void {
    if (atmApiBaseUrl) {
      localStorage.setItem('atmApiBaseUrl', atmApiBaseUrl);
    } else {
      localStorage.removeItem('atmApiBaseUrl');
    }

    stateSetAtmApiBaseUrl(atmApiBaseUrl || undefined);
  }
  function setLoginToken(token: string | null): void {
    if (token) {
      localStorage.setItem('loginToken', token);
    } else {
      localStorage.removeItem('loginToken');
    }

    stateSetLoginToken(token);
  }

  function onError(
    requestUrl: string,
    headers: Headers,
    error: {
      data: any;
      status?: number;
      [key: string]: any;
    },
  ) {
    // Restful react only automatically parses JSON responses if they have the content type application/json but
    // api platform returns 'application/problem+json' for it's errors so we need to parse it manually.
    const contentType = headers.get('content-type');
    if (typeof error.data === 'string' && contentType && contentType.startsWith('application/problem+json')) {
      error.data = JSON.parse(error.data);
    }

    // Unset login token if we get a 401 Unauthorized or 403 Forbidden form the tdf backend
    // (see `ATMAttendeeAuthenticator::start()` for more)
    if (
      requestUrl.startsWith(process.env.REACT_APP_API_ENTRYPOINT as string) &&
      (error.status === 401 || error.status === 403)
    ) {
      setLoginToken(null);
    }

    // Handle 401 Unauthorized errors from the ATM backend (either unset public token or login token)
    if (atmApiBaseUrl && requestUrl.startsWith(atmApiBaseUrl) && error.status === 401) {
      // Strip base url from the request url. Will be /sites, /attendee, etc.
      const requestUrlWithoutBase = requestUrl.substr(atmApiBaseUrl.length + 3);

      // Ignore /login calls, the login form will handle errors for this url
      if (requestUrlWithoutBase === '/login') {
        return;
      }

      //  If api call starts with /attendee or /team this is a private api call -> unset the login token
      if (requestUrlWithoutBase.startsWith('/attendee') || requestUrlWithoutBase.startsWith('/team')) {
        setLoginToken(null);

        return;
      }

      // Else unset atm base url which triggers a refetch of the public api token and causes all api hooks to
      // re-run.
      setAtmApiBaseUrl(undefined);
    }
  }

  return (
    <ApiContext.Provider
      value={{
        apiError,
        setApiError,
        atmApiBaseUrl,
        setAtmApiBaseUrl,
        loginToken,
        setLoginToken,
      }}
    >
      {/* @ts-ignore */}
      <RestfulProvider
        base=""
        onError={(error, retry, response) => onError(response?.url as string, response?.headers as Headers, error)}
      >
        {children}
      </RestfulProvider>
    </ApiContext.Provider>
  );
};

export default ApiProvider;
