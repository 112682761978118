import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { LoaderWrapper } from '../components/Loader';
import SiteLoader from '../components/SiteLoader';

// Dynamically import pages so that only the files needed for the current site get loaded over the network
const Homepage = lazy(() => import('../pages/Homepage'));
const Register = lazy(() => import('../pages/Register'));
const Activate = lazy(() => import('../pages/Activate'));
const JoinTeam = lazy(() => import('../pages/JoinTeam'));
const Login = lazy(() => import('../pages/Login'));
const PasswortVergessen = lazy(() => import('../pages/PasswortVergessen'));
const Logout = lazy(() => import('../pages/Logout'));
const DieAktion = lazy(() => import('../pages/Aktion'));
const Spielregeln = lazy(() => import('../pages/Spielregeln'));
const RichtigAufladen = lazy(() => import('../pages/RichtigAufladen'));
const Trinktagebuch = lazy(() => import('../pages/Trinktagebuch'));
const DiaryArchive = lazy(() => import('../pages/DiaryArchive'));
const TeamDiary = lazy(() => import('../pages/TeamDiary'));
const TeamDiaryArchive = lazy(() => import('../pages/TeamDiaryArchive'));
const Rankings = lazy(() => import('../pages/Rankings'));
const Daten = lazy(() => import('../pages/Daten'));
const Teilnahmebedingungen = lazy(() => import('../pages/Teilnahmebedingungen'));
const Kontakt = lazy(() => import('../pages/Kontakt'));

export default () => (
  <Suspense fallback={<LoaderWrapper overlay={true} />}>
    <Switch>
      <Route
        exact
        path="/"
        component={Homepage}
      />
      <Route
        exact
        path="/registrieren/:id-:name"
        component={Register}
      />
      <Route
        exact
        path="/aktivieren"
        component={Activate}
      />
      <Route
        exact
        path="/team-beitreten"
        component={JoinTeam}
      />
      <Route
        exact
        path="/login"
        component={Login}
      />
      <Route
        exact
        path="/login/passwort-vergessen"
        component={PasswortVergessen}
      />
      <Route
        exact
        path="/logout"
        component={Logout}
      />
      <Route
        exact
        path="/die-aktion"
        component={DieAktion}
      />
      <Route
        exact
        path="/die-aktion/spielregeln"
        component={Spielregeln}
      />
      <Route
        path="/richtig-aufladen"
        component={RichtigAufladen}
      />
      <Route
        exact
        path="/trinktagebuch"
        component={Trinktagebuch}
      />
      <Route
        exact
        path="/trinktagebuch/archiv"
        component={DiaryArchive}
      />
      <Route
        exact
        path="/team"
        component={TeamDiary}
      />
      <Route
        exact
        path="/team/archiv"
        component={TeamDiaryArchive}
      />
      <Route
        exact
        path="/rankings"
        component={Rankings}
      />
      <Route
        exact
        path="/daten-aendern"
        component={Daten}
      />
      <Route
        exact
        path="/teilnahmebedingungen"
        component={Teilnahmebedingungen}
      />
      <Route
        exact
        path="/kontakt-aufnehmen"
        component={Kontakt}
      />
      {/* Try to load all unknown sites via the api */}
      <Route
        exact
        path="/:name+"
        render={(props) => (
          <SiteLoader
            key={props.match.params.name}
            {...props}
          />
        )}
      />
    </Switch>
  </Suspense>
);
