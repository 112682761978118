import React from 'react';
import { Grid } from 'react-bootstrap';
import { useGetSite } from '../api/atmPublic';
import Breadcrumbs from '../layout/Breadcrumbs';
import Wrapper from '../layout/Wrapper';
import Loader from './Loader';
import PageNotFound from '../pages/PageNotFound';

export default ({
  match: {
    params: { name },
  },
}) => {
  const { data, error } = useGetSite({ name: encodeURIComponent(name) });

  if (error) {
    // If error, site was not found
    return <PageNotFound />;
  }

  if (!data) {
    return (
      <Wrapper>
        <Breadcrumbs />
        <Grid fluid={true}>
          <Loader overlay={false} />
        </Grid>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Breadcrumbs pageTitle={data && data.title} />
      <Grid fluid={true}>
        <h1>{data.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: data && data.content }} />
      </Grid>
    </Wrapper>
  );
};
