import React from 'react';

const Wrapper = (props) => {
  return (
    <div className="content-wrap">
      <div className="section__wrap">{props.children}</div>
    </div>
  );
};

export default Wrapper;
