import React from 'react';
import './CookieLayer.scss';

const CookieLayer = ({ setShowCookie }) => {
  const acceptCookieNote = () => {
    window.localStorage.setItem('cookieAccepted', 'true');
    setShowCookie(false);
  };

  return (
    <div
      id="mmsCookieLayer"
      className="modal fade modal-cookie-layer in"
      role="dialog"
      style={{ display: 'block' }}
    >
      <div
        className="modal-dialog modal-dialog-scrollable modal-dialog-md"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <h2>Diese Webseite verwendet Cookies</h2>
            <p>
              Wir verwenden erforderliche Cookies, die für den Betrieb der Webseite notwendig sind, um Ihnen ein
              optimales Webseiten-Erlebnis zu bieten.
            </p>
          </div>

          <div className="cookie-layer-button modal-footer">
            <button
              type="button"
              onClick={acceptCookieNote}
              className="btn btn--primary"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieLayer;
